import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Loading from './Loading';
import { Helmet } from "react-helmet";
import BackButton from './BackButton';
import TeamCard from './TeamCard';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
export default function Team() {
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const [value, setValue] = useState("");
    const [userName, setUserName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        GetTeam();
        let URL = "";
        let uName = "";
        let xCode = JSON.parse(localStorage.getItem("UserDetails").hexDecode());
        uName = xCode.UserName;
        URL = xCode.ReferralURL;
        setValue(URL + uName);
        setUserName(uName);
    }, []);
    const [myteam, setMyTeam] = useState({
        asset: "0",
        recharge: "0",
        totalTeam: "0",
        teamACount: 0,
        teamBCount: 0,
        teamCCount: 0,
        teamDCount: 0,
        teamECount: 0,
        teamFCount: 0,
        teamARecharge: 0,
        teamBRecharge: 0,
        teamCRecharge: 0,
        teamDRecharge: 0,
        teamERecharge: 0,
        teamFRecharge: 0
    })
    const GetTeam = () => {
        setIsLoading(true);
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "Team";
        var obj = new Object();
        obj.procName = 'AppReportsNew';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setMyTeam({
                asset: res[0].TotalTeamEarning, recharge: res[0].TotalTeamRecharge, totalTeam: res[0].TotalTeamCount,
                teamACount: res[0].TeamACount,
                teamBCount: res[0].TeamBCount,
                teamCCount: res[0].TeamCCount,
                teamDCount: res[0].TeamDCount,
                teamECount: res[0].TeamECount,
                teamFCount: res[0].TeamFCount,
                teamARecharge: res[0].TeamABenefit,
                teamBRecharge: res[0].TeamBBenefit,
                teamCRecharge: res[0].TeamCBenefit,
                teamDRecharge: res[0].TeamDBenefit,
                teamERecharge: res[0].TeamEBenefit,
                teamFRecharge: res[0].TeamFBenefit,
            });
            console.log(myteam.LevelOneTeam);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const CopyCallBack = (t, r) => {
        if (r == true) {
            toast.success("Copied");
        }
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Team</title>
                <link rel="stylesheet" href="css/myteam.css" />
                <link rel="stylesheet" href="css/bootstrap.min.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
                <script src="js/jquery.min.js"></script>
                <script src="js/bootstrap.min.js"></script>
            </Helmet>
            {isLoading ? <Loading /> : ""}
            <div className="recharge-wrap" style={{ backgroundColor: 'none', paddingBottom: 2, overflow: 'hidden' }}>
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>My Team</span>

                </div>


                <div className='row shadowBox' style={{ height:135,padding: 10, background: 'url(https://t4.ftcdn.net/jpg/04/90/91/81/360_F_490918183_627MXWxdL8JkEBayMsga8hOqYRxg0Wsr.jpg)', backgroundPosition: 'left', borderRadius: '11px', boxShadow: '0px 0px 1px 0px red' }}>
                    <div className='col-5'>
                        <img src='img/tronlogo.png' className='productImg' style={{ borderRadius: '0%',height:114,filter:'invert(0)' }} alt />
                    </div>
                    <div className='col-7' style={{ fontSize: '0.29rem', padding: '0px' }}>
                        <div ><b style={{ fontSize: 16, color: '#fff' }}>Team Size</b></div>
                        <div ><b style={{ marginLeft: '0%', fontSize: 16, color: '#fff' }}>{myteam.totalTeam}</b></div>

                        <div>
                            <span style={{ fontSize: 13, verticalAlign: 'baseline', color: '#fff' }}></span> <span style={{ color: '#fff', fontSize: 16 }}>Team Recharge</span>
                        </div>
                        <div>
                            <span style={{ color: '#fff', fontSize: 15 }}>{myteam.recharge} USDT</span>
                        </div>
                    </div>
                </div>
                {/* <div className='col-6'>
                        <div className='invitaionLink'>
                            <div style={{ fontSize: '14px' }}><b>Invitation Link</b></div>
                            <div style={{ fontSize: '12px', height: 35,width:'100%' }}>{value}</div>
                            <CopyToClipboard text={value} onCopy={CopyCallBack}>
                                <a className='aBtnLink'>Copy the link</a>
                            </CopyToClipboard>
                        </div>
                    </div> */}

            </div>




            <div className="profilecard back1">
                <div className="img">
                    <img src="img/level1.png" />
                </div>
                <div className="infos">
                    <div className="name">
                        <h2>TEAM A</h2>
                        {/* <h4>@bradsteve</h4> */}
                    </div>
                    <p className="text">
                        Level 1 Team Details
                    </p>
                    <ul className="stats">
                        <li>
                            <h3>{myteam.teamACount}</h3>
                            <h4>COUNT</h4>
                        </li>
                        <li>
                            <h3>{myteam.teamARecharge} <span style={{ fontSize: 10 }}>USDT</span></h3>
                            <h4>RECHARGE</h4>
                        </li>
                    </ul>
                </div>
                <div className="right">
                    <span onClick={() => routeChange(`/teambylevel/1`)}>View</span>
                    <div className="rank">
                        1<div className="rnk">LEVEL</div>
                    </div>
                </div>
            </div>
            <div className="profilecard back2">
                <div className="img">
                    <img src="img/level2.png" />
                </div>
                <div className="infos">
                    <div className="name">
                        <h2>TEAM B</h2>
                        {/* <h4>@bradsteve</h4> */}
                    </div>
                    <p className="text">
                        Level 2 Team Details
                    </p>
                    <ul className="stats">
                        <li>
                            <h3>{myteam.teamBCount}</h3>
                            <h4>COUNT</h4>
                        </li>
                        <li>
                            <h3>{myteam.teamBRecharge} <span style={{ fontSize: 10 }}>USDT</span></h3>
                            <h4>RECHARGE</h4>
                        </li>
                    </ul>
                </div>
                <div className="right">
                    <span onClick={() => routeChange(`/teambylevel/2`)}>View</span>
                    <div className="rank">
                        2<div className="rnk">LEVEL</div>
                    </div>
                </div>
            </div>
            <div className="profilecard back3">
                <div className="img">
                    <img src="img/level3.png" />
                </div>
                <div className="infos">
                    <div className="name">
                        <h2>TEAM C</h2>
                        {/* <h4>@bradsteve</h4> */}
                    </div>
                    <p className="text">
                        Level 3 Team Details
                    </p>
                    <ul className="stats">
                        <li>
                            <h3>{myteam.teamCCount}</h3>
                            <h4>COUNT</h4>
                        </li>
                        <li>
                            <h3>{myteam.teamCRecharge} <span style={{ fontSize: 10 }}>USDT</span></h3>
                            <h4>RECHARGE</h4>
                        </li>
                    </ul>
                </div>
                <div className="right">
                    <span onClick={() => routeChange(`/teambylevel/3`)}>View</span>
                    <div className="rank">
                        3<div className="rnk">LEVEL</div>
                    </div>
                </div>
            </div>
            <div className="profilecard back4">
                <div className="img">
                    <img src="img/level3.png" />
                </div>
                <div className="infos">
                    <div className="name">
                        <h2>TEAM D</h2>
                        {/* <h4>@bradsteve</h4> */}
                    </div>
                    <p className="text">
                        Level 3 Team Details
                    </p>
                    <ul className="stats">
                        <li>
                            <h3>{myteam.teamDCount}</h3>
                            <h4>COUNT</h4>
                        </li>
                        <li>
                            <h3>{myteam.teamDRecharge} <span style={{ fontSize: 10 }}>USDT</span></h3>
                            <h4>RECHARGE</h4>
                        </li>
                    </ul>
                </div>
                <div className="right">
                    <span onClick={() => routeChange(`/teambylevel/4`)}>View</span>
                    <div className="rank">
                        4<div className="rnk">LEVEL</div>
                    </div>
                </div>
            </div>
            <div className="profilecard back5">
                <div className="img">
                    <img src="img/level3.png" />
                </div>
                <div className="infos">
                    <div className="name">
                        <h2>TEAM E</h2>
                        {/* <h4>@bradsteve</h4> */}
                    </div>
                    <p className="text">
                        Level 3 Team Details
                    </p>
                    <ul className="stats">
                        <li>
                            <h3>{myteam.teamECount}</h3>
                            <h4>COUNT</h4>
                        </li>
                        <li>
                            <h3>{myteam.teamERecharge} <span style={{ fontSize: 10 }}>USDT</span></h3>
                            <h4>RECHARGE</h4>
                        </li>
                    </ul>
                </div>
                <div className="right">
                    <span onClick={() => routeChange(`/teambylevel/5`)}>View</span>
                    <div className="rank">
                        5<div className="rnk">LEVEL</div>
                    </div>
                </div>
            </div>
            <div className="profilecard back6">
                <div className="img">
                    <img src="img/level3.png" />
                </div>
                <div className="infos">
                    <div className="name">
                        <h2>TEAM F</h2>
                        {/* <h4>@bradsteve</h4> */}
                    </div>
                    <p className="text">
                        Level 6 Team Details
                    </p>
                    <ul className="stats">
                        <li>
                            <h3>{myteam.teamFCount}</h3>
                            <h4>COUNT</h4>
                        </li>
                        <li>
                            <h3>{myteam.teamFRecharge} <span style={{ fontSize: 10 }}>USDT</span></h3>
                            <h4>RECHARGE</h4>
                        </li>
                    </ul>
                </div>
                <div className="right">
                    <span onClick={() => routeChange(`/teambylevel/6`)}>View</span>
                    <div className="rank">
                        6<div className="rnk">LEVEL</div>
                    </div>
                </div>
            </div>
            <div className="container teamInfo10">
                {/* <ul className="nav nav-tabs" style={{ backgroundColor: '#212529', borderRadius: '7px' }}>
                            <li className="active myli"><a data-toggle="tab" href="#FirstLevel"><i className='fa fa-users'></i> Squad A</a></li>
                            <li className='myli'><a data-toggle="tab" href="#Level2"><i className='fa fa-users'></i> Squad B</a></li>
                            <li className='myli'><a data-toggle="tab" href="#Level3"><i className='fa fa-users'></i> Squad C</a></li>
                        </ul>
                        <div className="tab-content">
                            <div id="FirstLevel" className="tab-pane fade in active" style={{ minHeight: 370, opacity: 1, overflowX: 'hidden', overflowY: 'auto' }}>


                                <TeamCard data={myteam.LevelOneTeam} />


                            </div>
                            <div id="Level2" className="tab-pane fade in" style={{ minHeight: 370, opacity: 1, overflowX: 'hidden', overflowY: 'auto' }}>

                                <div className='table-responsive'>
                                    <TeamCard data={myteam.LevelTwoTeam} />
                                </div>

                            </div>
                            <div id="Level3" className="tab-pane fade in" style={{ minHeight: 370, opacity: 1, overflowX: 'hidden', overflowY: 'auto' }}>

                                <div className='table-responsive'>
                                    <TeamCard data={myteam.LevelThreeTeam} />
                                </div>

                            </div>
                        </div> */}

                <div data-v-0f319d31 className="team-card" hidden>

                    <div data-v-0f319d31 className="team-item" style={{ height: '50%' }}>
                        <div data-v-0f319d31 className="level-name">
                            <img data-v-0f319d31 src="img/level1.png" alt />
                            <div data-v-0f319d31 className="level-num">LEV 1</div>
                        </div><div data-v-0f319d31 className="level-count">
                            <div data-v-0f319d31 className="text-sm" style={{ fontSize: 14 }}>Team size</div>
                            <div data-v-0f319d31 className="text-df txtWhite text-bold">{myteam.teamACount}</div>
                        </div><div data-v-0f319d31 className="level-count">
                            <div data-v-0f319d31 className="text-sm" style={{ fontSize: 14 }}>Team recharge</div>
                            <div data-v-0f319d31 className="text-df txtWhite text-bold">{myteam.teamARecharge} USDT</div>
                        </div><div data-v-0f319d31 className="btn cp mybtn" onClick={() => routeChange(`/teambylevel/1`)}> View </div>

                    </div><div data-v-0f319d31 className="team-item" style={{ height: '50%' }}>
                        <div data-v-0f319d31 className="level-name">
                            <img data-v-0f319d31 src="img/level2.png" alt />
                            <div data-v-0f319d31 className="level-num">LEV 2</div>
                        </div><div data-v-0f319d31 className="level-count">
                            <div data-v-0f319d31 className="text-sm" style={{ fontSize: 14 }}>Team size</div>
                            <div data-v-0f319d31 className="text-df txtWhite text-bold">{myteam.teamBCount}</div>
                        </div><div data-v-0f319d31 className="level-count">
                            <div data-v-0f319d31 className="text-sm" style={{ fontSize: 14 }}>Team recharge</div>
                            <div data-v-0f319d31 className="text-df txtWhite text-bold">{myteam.teamBRecharge} USDT</div>
                        </div><div data-v-0f319d31 className="btn cp mybtn" onClick={() => routeChange(`/teambylevel/2`)}> View </div>

                    </div><div data-v-0f319d31 className="team-item" style={{ height: '50%' }}>
                        <div data-v-0f319d31 className="level-name">
                            <img data-v-0f319d31 src="img/level3.png" alt />
                            <div data-v-0f319d31 className="level-num" >LEV 3</div>
                        </div><div data-v-0f319d31 className="level-count">
                            <div data-v-0f319d31 className="text-sm" style={{ fontSize: 14 }}>Team size</div>
                            <div data-v-0f319d31 className="text-df txtWhite text-bold">{myteam.teamCCount}</div>
                        </div><div data-v-0f319d31 className="level-count">
                            <div data-v-0f319d31 className="text-sm" style={{ fontSize: 14 }}>Team recharge</div>
                            <div data-v-0f319d31 className="text-df txtWhite text-bold">{myteam.teamCRecharge} USDT</div>
                        </div><div data-v-0f319d31 className="btn cp mybtn" onClick={() => routeChange(`/teambylevel/3`)}> View </div></div>
                </div>


                <center><img src='img/logo.png' className='partnerPayment'></img></center>
            </div>


            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    ul {
                        list-style: none;
                      }
                  
                      /* Utilities */
                      .profilecard::after,
                      .profilecard img {
                        border-radius: 50%;
                      }
                  
                      
                      .profilecard,
                      .stats {
                        display: flex;
                      }
                  
                      .back1 {
                        background: linear-gradient(320deg, #6b4ca3, #d8bfbf);
                      }
                  
                      .back2 {
                        background: linear-gradient(320deg, #FF5C00, #dac938);
                      }
                      .back3 {
                        background: linear-gradient(320deg, #abba00, #dac938);
                      }
                      .back4 {
                        background: linear-gradient(320deg, #28a745, #dac938);
                      }
                      .back5 {
                        background: linear-gradient(320deg, #007bff, #dac938);
                      }
                      .back6 {
                        background: linear-gradient(320deg, #007bff, #dac938);
                      }
                      .profilecard {
                        padding: 14px 0px 0px 14px;
                        border-radius: 10px;
                  
                        max-width: 500px;
                        box-shadow: 0 2px 15px rgb(0 0 0 / 30%);
                        margin: 1rem;
                        position: relative;
                        transform-style: preserve-3d;
                        overflow: hidden;
                        color: #fff;
                        margin-bottom: 18px !important;
                      }
                  
                      .profilecard::after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                      }
                  
                   
                      .back1::after {
                        height: 225px;
                        width: 225px;
                        background: linear-gradient(320deg, #6b4ca3, #d8bfbfb0);
                        top: -4px;
                        right: -106px;
                        box-shadow: 2rem 6rem 0 -3rem #FFF;
                      }
                  
                      .back2::after {
                        height: 225px;
                        width: 225px;
                        background: linear-gradient(320deg, #FF5C00, #e0cf40);
                        top: -4px;
                        right: -106px;
                        box-shadow: 2rem 6rem 0 -3rem #FFF;
                      }
                      .back3::after {
                        height: 225px;
                        width: 225px;
                        background: linear-gradient(320deg, #dac938, #e0cf40);
                        top: -4px;
                        right: -106px;
                        box-shadow: 2rem 6rem 0 -3rem #FFF;
                      }
                      .back4::after {
                        height: 225px;
                        width: 225px;
                        background: linear-gradient(320deg, #dac938, #e0cf40);
                        top: -4px;
                        right: -106px;
                        box-shadow: 2rem 6rem 0 -3rem #FFF;
                      }
                      .back5::after {
                        height: 225px;
                        width: 225px;
                        background: linear-gradient(320deg, #dac938, #e0cf40);
                        top: -4px;
                        right: -106px;
                        box-shadow: 2rem 6rem 0 -3rem #FFF;
                      }
                        .back6::after {
                        height: 225px;
                        width: 225px;
                        background: linear-gradient(320deg, #dac938, #e0cf40);
                        top: -4px;
                        right: -106px;
                        box-shadow: 2rem 6rem 0 -3rem #FFF;
                      }
                      .profilecard img {
                        width: 75px;
                        min-width: 48px;
                        box-shadow: 0 0 0 0px #FFF;
                      }
                  
                      .infos {
                        margin-left: 1.5rem;
                      }
                  
                      .name {
                        margin-bottom: 0rem;
                      }
                  
                      .name h2 {
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: 1px;
                      }
                  
                      .name h4 {
                        font-size: 15px;;
                        color: #333
                      }
                  
                      .text {
                        font-size: 15px;
                        margin-bottom: 25px;
                    }
                  
                      .stats {
                        margin-bottom:0px;
                        padding-left:0px!important;
                      }
                  
                      .stats li {
                        min-width:85px;
                      }
                  
                      .stats li h3 {
                        font-size: 19px;
                        margin-bottom: 5px;
                      }
                  
                      .stats li h4 {
                        font-size: 12px;
                      }
                  
                      .links button {
                        font-family: 'Poppins', sans-serif;
                        min-width: 120px;
                        padding: .5rem;
                        border: 1px solid #222;
                        border-radius: 5px;
                        font-weight: bold;
                        cursor: pointer;
                        transition: all .25s linear;
                      }
                  
                      .links .follow,
                      .links .view:hover {
                        background-color: #222;
                        color: #FFF;
                      }
                  
                      .links .view,
                      .links .follow:hover {
                        background-color: transparent;
                        color: #222;
                      }
                  
                      .right {
                        text-align: right;
                        font-size: 15px;
                        width: 153px;
                  
                      }
                  
                      .right span {
                        position: absolute;
                        top: 10px;
                        right: 18px;
                        font-size: 13px;
                        background-color: #000;
                        padding: 5px;
                        border-radius: 5px;
                      }
                  
                      .rank {
                        margin-top: 50px;
                        text-align: center;
                        color: #fff;
                        font-size: 17px;
                      }
                  
                      @media screen and (max-width: 450px) {
                        .profilecard {
                          margin: 9px;
                        }
                  
                        .infos {
                            margin-left: 18px;
                            margin-top: 0rem;
                            width: 150px;
                        }
                  
                        .links button {
                          min-width: 100px;
                        }
                      }
                    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl{
                        padding-right: 0px!important;
                        padding-left: 0px!important;
                    }
                    .partnerPayment{
                        margin-bottom: 100px!important;
                        height: 100px;
                        border-radius: 120px;
                        background-color: black;
                    
                    }
                    .mybtn{
                        font-size:14px!important;
                        line-height:1em!important;
                        height:28px;
                    }
                    .teamInfo10 .team-card[data-v-0f319d31] {
                        margin: 15px auto 0;
                        line-height: 24px;
                        width: calc(100% - 20px);
                        -webkit-box-pack: justify;
                        -webkit-justify-content: space-between;
                        -ms-flex-pack: justify;
                        justify-content: space-between
                    }
                    
                    .teamInfo10 .team-card .team-item[data-v-0f319d31],.teamInfo10 .team-card[data-v-0f319d31] {
                        box-sizing: border-box;
                        border-radius: 15px;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex
                    }
                    
                    .teamInfo10 .team-card .team-item[data-v-0f319d31] {
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -webkit-flex-direction: column;
                        -ms-flex-direction: column;
                        flex-direction: column;
                        margin-bottom: 20px;
                        width: 32%;
                        padding: 10px
                    }
                    
                    .teamInfo10 .team-card .team-item[data-v-0f319d31]:first-child {
                        background: -webkit-linear-gradient(229.45deg,#ffc107,#39a0ff);
                        background: linear-gradient(220.55deg,#ffc107,#39a0ff)
                    }
                    
                    .teamInfo10 .team-card .team-item[data-v-0f319d31]:nth-child(2) {
                        background: -webkit-linear-gradient(229.45deg,#ffbb89,#7b6ae0);
                        background: linear-gradient(220.55deg,#ffbb89,#7b6ae0)
                    }
                    
                    .teamInfo10 .team-card .team-item[data-v-0f319d31]:nth-child(3) {
                        background: -webkit-linear-gradient(229.45deg,#ff3f3f,#063cff);
                        background: linear-gradient(220.55deg,#ff3f3f,#063cff)
                    }
                    
                    .teamInfo10 .team-card .team-item .level-name[data-v-0f319d31] {
                        text-align: center;
                        font-weight: 500;
                        border-radius: 30px;
                        color: var(--text-gray);
                        font-size: 14px;
                        font-weight: 700;
                        white-space: nowrap;
                        margin-bottom: 10px;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -webkit-align-items: center;
                        -ms-flex-align: center;
                        align-items: center
                    }
                    
                    .teamInfo10 .team-card .team-item .level-name .level-num[data-v-0f319d31] {
                        font-weight: 700;
                        margin-left: 5px;
                        font-size: 16px;
                        color: var(--text-white)
                    }
                    
                    .teamInfo10 .team-card .team-item .level-name img[data-v-0f319d31] {
                        width: 50px;
                        display: block
                    }
                    
                    .teamInfo10 .team-card .team-item .level-name .level[data-v-0f319d31] {
                        margin-top: 10px;
                        background: -webkit-linear-gradient(135deg,#1b0a25,#090514);
                        background: linear-gradient(-45deg,#1b0a25,#090514);
                        display: inline-block;
                        padding: 2px 10px;
                        font-size: 12px;
                        line-height: 14px;
                        border-radius: 10px;
                        color: var(--text-white)
                    }
                    
                    .teamInfo10 .team-card .team-item .level-count[data-v-0f319d31] {
                        text-align: center;
                        color: #e9e9d9;
                        margin-bottom: 10px
                    }
                    
                    .teamInfo10 .team-card .team-item .level-count .text-sm[data-v-0f319d31] {
                        line-height: 16px;
                        margin-bottom: 5px
                    }
                    
                    .teamInfo10 .team-card .team-item .level-count .text-df {
                        font-size: 16px
                    }
                    
                    .teamInfo10 .team-card .team-item .btn[data-v-0f319d31] {
                        display: inline-block;
                        padding: 4px 10px;
                        text-align: center;
                        border-radius: 15px;
                        line-height: 17px;
                        color: var(--btn-text);
                        font-size: 12px;
                        font-weight: 700;
                        background: -webkit-linear-gradient(229.45deg,#fadd76,#9f3311);
                        background: linear-gradient(220.55deg,#fadd76,#ffc107)
                    }
                    
                    .teamInfo10 .total_infinite_recharge_money[data-v-0f319d31] {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: justify;
                        -webkit-justify-content: space-between;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        margin-top: 12px;
                        padding: 24px;
                        box-sizing: border-box;
                        border-radius: 5px;
                        line-height: 24px;
                        background: var(--bg-card);
                        box-shadow: var(--shadow)
                    }
                    
                    .teamInfo10 .btnOk[data-v-0f319d31] {
                        margin-top: 0
                    }
                    
                    .teamInfo10 .levelInfo[data-v-0f319d31] {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -webkit-align-items: center;
                        -ms-flex-align: center;
                        align-items: center;
                        background-color: var(--bg-card);
                        padding: 16px;
                        margin-bottom: 12px;
                        border-radius: 12px;
                        box-shadow: var(--box-shadow)
                    }
                    
                    .teamInfo10 .levelInfo .levelItem[data-v-0f319d31] {
                        width: 33.3%;
                        border-radius: 8px;
                        text-align: center;
                        line-height: 30px;
                        margin: 0 10px
                    }
                    
                    .teamInfo10 .levelInfo .levelName[data-v-0f319d31] {
                        height: 30px;
                        line-height: 30px;
                        background: var(--primary);
                        color: var(--text-white);
                        border-radius: 8px
                    }
                    
                    .teamInfo10 .levelInfo .active[data-v-0f319d31] {
                        background: var(--primary);
                        border-radius: 8px
                    }
                    
                    .teamInfo10 .levelInfo .txtBlue[data-v-0f319d31] {
                        font-size: 16px;
                        margin-right: 6px;
                        font-weight: 700
                    }
                    
                    .teamInfo10 .levelInfo .tips[data-v-0f319d31] {
                        font-size: 14px
                    }
                    
                    .teamInfo10 .listTableInfo[data-v-0f319d31] {
                        background-color: var(--bg-card);
                        border-radius: 12px
                    }
                    
                    .teamInfo10 .listTableInfo .table[data-v-0f319d31],.teamInfo10 .listTableInfo[data-v-0f319d31] {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -webkit-flex-direction: column;
                        -ms-flex-direction: column;
                        flex-direction: column
                    }
                    
                    .teamInfo10 .listTableInfo .table[data-v-0f319d31] {
                        font-size: 14px
                    }
                    
                    .teamInfo10 .listTableInfo .table .header[data-v-0f319d31] {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -webkit-align-items: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: justify;
                        -webkit-justify-content: space-between;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        padding: 10px 12px;
                        border-bottom: 1px solid var(--text-gray6)
                    }
                    
                    .teamInfo10 .listTableInfo .table .body[data-v-0f319d31] {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -webkit-flex-direction: column;
                        -ms-flex-direction: column;
                        flex-direction: column
                    }
                    
                    .teamInfo10 .listTableInfo .table .body .tr[data-v-0f319d31] {
                        -webkit-box-pack: justify;
                        -webkit-justify-content: space-between;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        padding: 10px 12px 18px 12px;
                        border-bottom: 1px solid var(--text-gray6)
                    }
                    
                    .teamInfo10 .listTableInfo .table .body .tr .td[data-v-0f319d31],.teamInfo10 .listTableInfo .table .body .tr[data-v-0f319d31] {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -webkit-align-items: center;
                        -ms-flex-align: center;
                        align-items: center
                    }
                    
                    .teamInfo10 .listTableInfo .table .body .tr .td[data-v-0f319d31]:first-child {
                        width: 30%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap
                    }
                    
                    .teamInfo10 .listTableInfo .table .body .tr .td .txt[data-v-0f319d31] {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex
                    }
                    
                    .teamInfo10 .listTableInfo .table .body .tr .td[data-v-0f319d31]::nth-last-child(1) {
                        -webkit-box-pack: start;
                        -webkit-justify-content: flex-start;
                        -ms-flex-pack: start;
                        justify-content: flex-start
                    }
                    
                    .teamInfo10 .listTableInfo .table .body .tr .td .icon[data-v-0f319d31] {
                        width: 14px;
                        height: 14px;
                        display: inline
                    }
                    
                    .teamInfo10 .listTableInfo .table .body .nomore[data-v-0f319d31] {
                        -webkit-box-pack: center;
                        -webkit-justify-content: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        height: 42px
                    }
                    
                    .teamInfo10 .listTableInfo .table .body .nomore[data-v-0f319d31],.teamInfo10 .task-card[data-v-0f319d31] {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -webkit-align-items: center;
                        -ms-flex-align: center;
                        align-items: center
                    }
                    
                    .teamInfo10 .task-card[data-v-0f319d31] {
                        padding: 16px 24px;
                        box-sizing: border-box;
                        line-height: 24px;
                        background: var(--bg-card);
                        width: calc(100% - 20px);
                        margin: 6px auto 0;
                        border-radius: 15px;
                        border: 1px solid var(--primary);
                        position: relative;
                        color: var(--btn-text);
                        font-size: 18px;
                        cursor: pointer
                    }
                    
                    .teamTask10[data-v-0f319d31] {
                        padding-bottom: 100px;
                        background: var(--bg)
                    }
                    
                    .teamTask10 .title[data-v-0f319d31] {
                        font-size: 14px;
                        color: var(--text-gray)
                    }
                    
                    .teamTask10 .content[data-v-0f319d31] {
                        font-size: 16px;
                        color: var(--text-white)
                    }
                    
                    .teamTask10 .mb-12px[data-v-0f319d31] {
                        margin-bottom: 12px
                    }
                    
                    .teamTask10 .task-card[data-v-0f319d31] {
                        margin: 12px auto 0;
                        width: calc(100% - 24px);
                        padding: 16px;
                        box-sizing: border-box;
                        border-radius: 12px;
                        line-height: 24px;
                        background: var(--bg-card)
                    }
                    .glow {
                        font-size: 20px;
                        color: #fff;
                        
                        -webkit-animation: glow 1s ease-in-out infinite alternate;
                        -moz-animation: glow 1s ease-in-out infinite alternate;
                        animation: glow 1s ease-in-out infinite alternate;
                      }
                      
                      @-webkit-keyframes glow {
                        from {
                          text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
                        }
                        to {
                          text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
                        }
                      }
                    .wallet{
                        background: url(img/banner.jpg);
                        background-size: 100% 100%;
                        border-radius: 31px;
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                        margin: 15px;
                      }
                      .myicon {
                        height: 106px;
                        position: absolute;
                        margin-left: 62%;
                        margin-top: -143px;
                      }
                    .myli{
                        border-radius: 0px;
                        background: #17a2b8;
                        padding: 9px;
                        color: #fff;
                    }
                    .mycustomRow{
                        color: #fff;
                        margin-top: 20px;
                        background: linear-gradient(270deg,#14d2be,rgb(24, 160, 138)) !important;
                        padding: 5p;
                        margin-left: 5%;
                        margin-right: 5%;
                        border-radius: 5px;
                        padding: 7px;
                    }
                    .otherSpan{
                        float: right;
                        margin-top: -33px;
                        margin-right: 81px;
                    }
                   
                    .myclass{
                        display: inline;
                        float: right;
                        position: absolute;
                        margin-left: 38%;
                        width: 100%;
                    }
                    .card{
                        background:none!important;
                    }
                    // body {
                    //     background:black url(http://fc01.deviantart.net/fs71/f/2012/160/b/9/stars_by_paulinemoss-d52un4e.jpg);
                    //     animation: stars 250s linear alternate;
                    //   }
                    //   @keyframes stars {
                    //     0% { background-position:0 0 }
                    //     100% { background-position:0 100% }
                    //   }
                    .myteam .banner {
                        height: 113px;
                    }
            
                    .nav-tabs {
                        display: flex;
                        justify-content: space-between;
                    }
                    .nav-tabs>li>a {
                        margin-right: 2px;
                        line-height: 1.42857143;
                        border: 1px solid transparent;
                        border-radius: 4px 4px 0 0;
                       
                        color: #000;
                        font-size:16px;
                    }
                    .myteam {
                        padding: 0px;
                        background: #fdfdfd;
                        overflow-x: hidden;
                overflow-y: auto;
                height: 425px;
                    }
                    .table tr th{
                        text-align:left !important;
                        font-weight:600 !important;
                        font-size:14px !important;
                    }
                    .table tr td,th{
                        padding:7px !important;
                        text-align:left !important;
                       
                    }
                    .tab-bar {
                        max-width: 750px;
                        left: 50%;
                        -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                        position: fixed;
                        bottom: 0px;
                        width: 100%;
                        height: 64px;
                        z-index: 30;
                       
                        padding: 10px 0px;
                        background-size: 100% 100%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                    }
            
                    .myteam .tips {
                        font-size: .28rem;
                        font-weight: 500;
                        line-height: .45rem;
                        color: #100d0d;
                        background: #ffc107;
                        padding-left: 5px;
                        padding: 5px 8px;
                        margin-bottom: 2px;
                        margin-top: 0px;
                    }
            
                    .tips i {
                        color: #f23e36 !important;
                    }
            
                    .myteam .list li > div {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        font-size: .26rem;
                        font-weight: 400;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        color: #262626;
                        margin-top: 0px !important;
                    }
            
                    .myteam .uls {
                        padding-left: 0px;
                    }
            
                    .myteam .list li span {
                        width: auto;
                        height: auto;
                        background: #f5fff700;
                        border-radius: 0.2rem;
                        font-size: .28rem;
                        font-weight: 500;
                        line-height: 10px;
                        color: #03296c;
                        margin-top: 0.08rem;
                        text-align: center;
                    }
            
                    .myteam .list li {
                        border-bottom: 1px solid #EEEEF0;
                        width: 47%;
                        list-style: none;
                    }
            
                    .myteam .uls li div:nth-child(2) p {
                        margin: 0px;
                    }
            
                    .myteam .uls li:nth-child(3) {
                        border-top: 1px solid #EEEEF0;
                        border-bottom: 1px solid #EEEEF0;
                    }
            
                    .my-item {
                        padding: 10px;
                        display: flex;
                        font-size: 14px;
                        border-bottom: 1px solid #f2f2f2;
                        background: #ffff;
                    }
            
                        .my-item img {
                            width: 20px;
                            height: 20px;
                        }
            
                        .my-item div {
                            padding-left: 20px;
                            width: 90%;
                            color: #000;
                        }
            
                        .my-item span {
                            width: 21px;
                            background-image: url(./img/arrow4.png);
                            background-size: 100%;
                            background-repeat: no-repeat;
                            filter: invert(1);
                            height: 20px;
                display: block;
                        }
            
                    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
                        color: #fff;
                        cursor: default;
                        font-weight: bold;
                        border: none;
                        background-color: transparent;
                        border: 2px solid #ffc107;
                        /* border-bottom-color: #fff; */
                        text-decoration: none;
                        padding: 5px;
                    }
                    .nav-tabs>li>a{
                        color:#fff;
                    }
                    .nav-tabs{
                        border:0px !important;
                    }
                    .nav-tabs li{
                        line-height:0px;
                    }
            
                    .time[data-v-b7e8b406] {
                        overflow: hidden;
                        padding: 5px;
                        line-height: 0.8rem;
                        border-bottom: 0.013333rem solid #eee;
                    }
            
                    .time p[data-v-5a05e720] {
                        float: left;
                        background: #f7f7f700;
                        border-radius: 0.4rem;
                        padding: 0 0.666667rem;
                        font-size: 0.32rem;
                    }
            
                    .time span[data-v-5a05e720] {
                        float: left;
                        padding: 0 0.266667rem;
                        font-size: 0.373333rem;
                    }
            
                    .search button[data-v-b7e8b406] {
                        background-color:#4D148C!important;
                        background-size: 0.4rem;
                        border-radius: 0.4rem;
                        padding: 0 0.266667rem 0 0.8rem;
                        line-height: 0.8rem;
                        float: right;
                        color: #fff;
                        font-size: 0.346667rem;
                        border: none;
                    }
                    .dataTables_info {
                       margin-bottom: 10px!important;
                    }
                    .previous {
                        padding: 3px 5px;
                background: #124296;
                color: #fff;
                border-radius: 4px;
                    }
                    .next {
                         padding: 3px 5px;
                background: #124296;
                color: #fff;
                border-radius: 4px;
                    }
          `
                }}
            />
        </>
    )
}

import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Loading from './Loading';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import BackButton from './BackButton';
import toast, { Toaster } from 'react-hot-toast';
import PaymentOptions from './PaymentOptions';
import RechargeOptions from './RechargeOptions';
export default function RechargeWallet() {


    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const inputEls = useRef([]);
    const [isShowPopup, setPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [walletAmount, setwalletAmount] = useState(0);
    const [coinType, setcoinType] = useState("0");
    const [Cointimage, setCointimage] = useState("");
    const [UPIData, setUPIOption] = useState([{ CoinName: "USDT(TRC20)", CoinType: "USDT(TRC20)", imageType: 'tether_trc20.png', exchangeRate: '1:1', minDeposit: 20 }, { CoinName: "USDT(BEP20)", CoinType: "USDT(BEP20)", imageType: 'tether_bep20.png', exchangeRate: '1:10', minDeposit: 20 },{ CoinName: "TRX", CoinType: "TRX", imageType: 'tronlogo.png', exchangeRate: '1:1', minDeposit: 172 }]);
    const [allValues, setAllValues] = useState({
        RechargeAmount: 0
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        GetWalletAmount();
    }, []);
    const ProceedToRecharge = () => {
        if (coinType == 0) {
            toast.error("Please Select Payment Options");
            return;
        }
        var minAmount = coinType == "TRX" ? 172 : coinType == "DOGECOIN" ? 25 : coinType == "BTC" ? 0.000080 : 20;
        if (allValues.RechargeAmount < minAmount) {
            toast.error("Minimum Recharge Amount is " + minAmount + (coinType == "TRX" ? " TRX" : coinType == "DOGECOIN" ? " DOGECOIN" : coinType == "BTC" ? " BTC" : ' USDT'));
            inputEls.current[1].focus();
            return;
        }

        navigate('../pay', { replace: true, state: { coinType: coinType, amount: allValues.RechargeAmount, coinImage: Cointimage } });
    }

    const GetWalletAmount = () => {
        setIsLoading(true);
        var param = new Object();
        param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "GetWalletAmount";
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setwalletAmount(res[0].ProductWallet);
            setUPIOption(JSON.parse(res[0].UPIData))
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const GetPayOption = (type, imageType) => {
        setcoinType(type);
        setCointimage(imageType);
        setPopup(true);
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Recharge</title>
                <link rel="stylesheet" href="css/Recharge.css" />
                <link rel="stylesheet" href="css/lease.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
            </Helmet>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div class="recharge-wrap" style={{ backgroundColor: '#fff' }}>
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Deposit</span>

                </div>
                <div className='row' style={{ margin: 5 }}>
                    <div className='col-12'>
                        <div className='grayblkmy' style={{ background:'url(https://mathwallet.org/web/static/img/chain/tron_banner.jpg)',backgroundPosition:'left',padding:10 }}>
                            <div style={{ height: '25px', }}><span className='normaltxt' style={{ color: '#fff' }}>Deposit Balance</span></div>
                            <div className='textFigure'><span style={{ color: '#fff' }}>{walletAmount} USDT</span></div>
                        </div>
                        
                    </div>
                </div>
                <div className="list-wrap">

                    <div className="rechargedetail" style={{marginTop: '5%', padding: 10, borderRadius: 8 }}>
                        <div style={{ display: 'block !important' }}>
                            {/* <div style={{ display: 'block !important', paddingBottom: 11 }}><div style={{ fontSize: '18px' }}>Choose Deposit Options</div></div> */}
                            <div style={{ display: 'block !important', lineHeight: '2px' }}>
                                <PaymentOptions data={UPIData} func={GetPayOption}></PaymentOptions>
                                {/* <RechargeOptions data={UPIData} func={GetPayOption}></RechargeOptions> */}
                            </div>
                        </div>

                    </div>

                    {/* <div className="rechargedetail" style={{ marginTop: '-12px', background: '#fff', padding: 10, borderRadius: 8 }}>
                        <button type="button" onClick={ProceedToRecharge} id="btnSubmit" style={{ margin: 0, marginBottom: '40px' }} className="btn btn-info" >PAY NOW <i class="fa fa-chevron-right"></i></button>
                    </div> */}
                    {isShowPopup ?
                        <div id="mask">

                            <div className="mask-body" onclick="stopPropagation()" style={{ height: 160, borderRadius: 10, background: '#000' }}>
                                <div style={{ position: 'relative', background: 'transparent', height: 'auto' }}>

                                </div>
                                <div style={{ fontSize: 14, background: 'transparent', color: '#fff', textAlign: 'center', width: '100%', marginTop: '15px' }}>
                                    Network: <span className='badge badge-warning'>{coinType}</span>
                                </div>
                                <div style={{ display: 'block !important' }}>

                                    <div style={{ display: 'block !important' }}>
                                        <input type="number" ref={(el) => (inputEls.current[1] = el)} onChange={changeHandler} className="number_only withInput" name='RechargeAmount' id="RechargeAmount" placeholder="Enter Payment Amount" style={{ width: '50%', height: '30px', fontSize: '0.4em',border: '1px solid #fff',borderRadius:5 }} />
                                    </div>
                                </div>

                               
                                <div style={{ position: 'fixed', bottom: '5px', padding: '10px', width: '100%', borderTop: 'solid 1px #ddd', lineHeight: '20px', borderRadius: '0px' }}>
                                    <div className='cancelButton' onClick={() => setPopup(false)}>Cancel</div>
                                    <div className='confirmButton' onClick={ProceedToRecharge}>Proceed</div>
                                </div>

                            </div>

                        </div>
                        : null
                    }
                    <div className="withdrawalInstruction" style={{ marginTop: '5%',background: '#eeeef0', padding: 10, borderRadius: 8, marginBottom: 0 }}>
                        <div style={{ paddingBottom: 5 }}>
                            <center style={{fontSize:15,fontWeight:'bold'}}>Recharge Notes</center>
                            <p className="tips" style={{ fontSize: 12, color: '#000',marginBottom:0 }}>
                                <div style={{ marginBottom: '7px' }}>1-You staking plan will be activated automatically once system will confirm payment.</div>
                                <div style={{ marginBottom: '7px' }}>2-For TRX Payment USDT Exchange rate is 1 TRX=0.12 USDT.</div>
                              
                            </p>
                        </div>
                    </div>
                    <input type="hidden" id="token" />
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .cancelButton{
                        float: left;
                        color: #fff;
                        padding: 5px;
                        background-color: #dc3545;
                        border-radius: 8px;
                    }
                    .confirmButton{
                        float: right;
                        padding: 5px;
                        background-color: #2fcc58;
                        color: #fff;
                        border-radius: 8px;
                    }
                    input[type=radio]{
                        transform:scale(1);
                      }
                      .radioLabel{
                        font-size:14px;
                        margin-left:5px;
                    }
                    .myicon{
                        height: 50px;
                        position: absolute;
                        margin-left: 77%;
                        margin-top: -61px;
                      }
                    .BalanceAmt{
                        margin-left:0px;
                        background-color:#fff;
                        color:#000!important;
                    }
                    .withInput{
                        font-size:13px!important;
                    }
                    .lock {
                        font-size: 18px !important;
                        color: #000 !important;
                        position: absolute !important;
                        top: 30px !important;
                        left: 12px !important;
                        z-index: 99 !important;
                    }
            
                    .list-wrap.list-wrap2 .price div {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        height: auto;
                        -webkit-box-align: end;
                        -ms-flex-align: end;
                        align-items: flex-end;
                        font-size: .6rem;
                        -webkit-transform: translateY(-0.22rem);
                        transform: translateY(-0.22rem);
                        margin-right: 11px !important;
                    }
            
                    .list-wrap.list-wrap2 .price {
                        background: #fff;
                        height: auto !important;
                        border-radius: 0.2rem;
                        padding-left: 0.2rem;
                        margin-top: 14px;
                    }
            
                        .list-wrap.list-wrap2 .price div input {
                            width: 90%;
                            font-size: 15px;
                            padding-left: 30px;
                            border: 1px solid #eee !important;
                            border-radius: 8px !important;
                            height: 40px;
                        }
            
                    input:focus {
                        outline: none !important;
                        border-color: #719ECE;
                    }
            
                    .list-wrap .btn {
                        line-height: 42px;
                    }
            
                    .topbg {
                        height: 82px !important;
                        overflow: hidden;
                        background: #ffffff;
                        background-size: 100% auto;
                        color: #FFFFFF;
                        padding-left: 0.26rem;
                    }
            
            
            
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 44px;
                        background: #4D148C;
                        border-radius: 4px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0.6rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        font-family: Microsoft YaHei !important;
                    }
            
                    .tips {
                        font-size: 10px;
                        color: #000!important;
                        font-style: italic;
                    }
          `
                }}
            />
        </>
    )
}

import React from 'react'
import { useNavigate } from "react-router-dom";
export default function BackButton(props) {
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    return (
        <div>
            <div className='col-12'>
                <div className='row'>
                    <div class="navbar navbar2">

                        <span style={{ marginRight: '25%', marginTop: '-15px' }}>About Us</span>
                        <span>
                        </span>
                    </div>
                    <div className='col-12' style={{ minHeight: '110vh' }}>

                        <div>
                            <center> <img src="img/logo.png" style={{ width: '100%', marginBottom: 2, height: 94, width: 180, backgroundColor: '#000', borderRadius: 11 }} alt /></center>
                        </div>
                       
                        <p style={{ fontSize: '0.26em', marginBottom: '10px', color: '#000' }}>
                            THxMining Limited is a British investment company working on the cryptocurrency market, Which includes different activities such as Crypto Trading, Crypto Mining and Crypto Investing. Cryptocurrency probably represents the most creative spirit of our generation. And helping people mine all the TRX, there is all we are about at MineTron. Since we first opened our doors in 2018 to eager folks looking to get their hands on as much TRX as they can mine on crypto mining platforms like our TronMiningFarm,we have never faulted on our mission to help crypto enthusiasts and investors alike realize their investment goal of earning extra money in this tech-driven, crypto-inspired age.
                        </p>
                        <h5 style={{ fontSize: 15 }}>Out Vision</h5>
                        <p style={{ fontSize: '0.26em', marginTop: -25, marginBottom: '10px', color: '#000' }}>Think of the time when you anticipate that 1 TRX will be 1$ in the bull market and think of how that journey could begin just now by signing up for the TronMiningFarm and watching your millionaire dream come true.</p>
                        <h5 style={{ fontSize: 15 }}>Out Approch</h5>
                        <p style={{ fontSize: '0.26em', marginTop: -25, marginBottom: '10px', color: '#000' }}>We are a team of Japanese, American, Chinese, Australian, Vietnamese, and British blockchain-enthused professionals with over a decade of mining experience. We are dedicated to providing you with simplified solutions to help mine TRX effortlessly.

                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

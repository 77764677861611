import React, { useState, useEffect, useRef } from 'react'
function TabDataFlexible(props) {
  const inputEls = useRef([]);

  return (
    <div>
      {props.data.map((item, i) => {
        const PackageList = (
          <div className='row shadowBox' style={{background:'#28a74500'}}>
            <div className='col-6'>
              <img src={item.PackageImage} className='productImg' alt />
            </div>
            <div className='col-6' style={{ fontSize: '0.29rem', padding: '0px' }}>
              <div ><b style={{ fontSize: 16 }}>{item.PackageName}</b></div>
              <div ><b style={{ marginLeft: '0%', fontSize: 16 }}>Period:{item.ReturnDays} Day</b></div>

              <div>
                <span style={{ fontSize: 13, verticalAlign: 'baseline' }}></span> <span>{item.Amount}</span>
              </div>
              <div>
                <span style={{ fontSize: 13 }}>Profit Daily:<span style={{ paddingLeft: '10px', color: 'blue' }}><span style={{ color: '#000',fontSize:15 }}>{item.DailyReturnPercentage}%</span></span></span>
              </div>
              <div>
                <span style={{ fontSize: 13 }}><span style={{ paddingLeft: '0px', color: 'blue' }}><span className='blink_me' style={{ color: '#28a745',fontSize:12,backgroundColor:'#000',padding:3 }}>{item.Description}</span></span></span>
              </div>
            </div>
            <hr />
            {/* <div className='col-5' style={{ fontSize: '16px', color: '#000', padding: '0px', paddingLeft: '10px' }}>
              <input type='number' name="PackageAmount" ref={(el) => (inputEls.current[i] = el)} style={{ width: '100%' }} placeholder='Enter Amount'></input>
            </div> */}
            {/* <div className='col-5'></div>
            <div className='col-5'><button onClick={() => props.func(item.PackageId, item.PackageName,item.InvestmentAmount)} className='btn btn-info btn-xs' style={{ width: '100px', fontSize: '0.3rem !important', padding: '0px', height: 36, float: 'right', marginBottom: '10px',backgroundColor:'#17a2b8' }}>Buy Now</button></div> */}

          </div>
        );
        return PackageList;
      })}
    </div>
  );
}

export default TabDataFlexible;
import React from 'react'
import { useNavigate } from "react-router-dom";
export default function Footer() {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate('../' + path, { replace: true });
  };
  return (
    <>
      <div className="tab-bar">
        <div className="active" onClick={() => routeChange('home')}>
          <i />
          <p>Home</p>
        </div>
        <div onClick={() => routeChange('invite')}>
          <i />
          <p>Invite</p>
        </div>
        <div onClick={() => routeChange('AboutCompany')}>
          <img src='img/leasingold.png' className='globe iconimage'></img>
          <p style={{ marginTop: 25, marginLeft: 10 }}>THxMining</p>
        </div>
        <div onClick={() => routeChange('team')}>
          <i />
          <p>Team</p>
        </div>
        <div onClick={() => routeChange('UserProfile')}>
          <i />
          <p>Me</p>
        </div>
      </div>

    </>
  )
}
